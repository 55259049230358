import * as styles from './quiz-template-page.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import React, { useState } from 'react';
import { faArrowRight, faSparkles } from '@fortawesome/pro-regular-svg-icons';

import Button from '../components/button';
import Callout from '../components/v2/callout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import InteractEmbed from '../components/utils/interact-embed';
import LazyImage from '../components/lazy-img';
import Modal from '../components/modal';
import PreviewCards from '../components/quiz-templates/preview-cards';
import SEO from '../components/seo';
import Section from '../components/section';
import sanitizeHtml from 'sanitize-html';

const QuizTemplatePageTemplate = ({ data }) => {
  // Initialize page data
  const quizImage = getImage(data.quizImage);
  const { id, title, img, content: rawData, category_record } = data.quizTemplate.data;
  const { slug, name, active } =
    (category_record && category_record[0] && category_record[0].data) || {};
  // const page = data.quizTemplatePage.data
  const content = JSON.parse(rawData);
  const { description, design } = content;

  const [preview, setPreview] = useState();

  return (
    <>
      <SEO
        title={`${title} | Interact Quiz`}
        description={`${sanitizeHtml(description, { allowedTags: [] })}`}
        image={{ src: img, width: `900`, height: `400` }}
        template={true}
      />
      <Header btnLabel="Make a quiz" btnLink="/" btnTheme="primary" btnType="internal" />
      <main className={styles.templatePage}>
        <Section style={{ marginTop: `4.5rem`, padding: `2rem 0 4rem 0` }}>
          <div className="row">
            <div className="col-lg-12">
              <ul className={styles.nav} style={{ marginBottom: `1rem` }}>
                <li>
                  <Link to="/quizzes/">Quizzes</Link>
                </li>
                <li className={styles.separator}>/</li>
                {slug && active ? (
                  <li>
                    <Link to={`/quizzes/${slug}/`}>{name} Quizzes</Link>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          <div
            className={`row justify-content-center align-items-stretch ${styles.templateCardOuter}`}>
            <div className="col-lg-7 order-1 text-md-center" style={{ padding: '0' }}>
              <div className={styles.templateCardInner}>
                <h1 className="heading-xl color-black">{title}</h1>
                <h2 className="heading-sm" style={{ marginBottom: '2rem' }}>{`${sanitizeHtml(
                  description,
                  { allowedTags: [] }
                )}`}</h2>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <button
                    className={styles.preview}
                    onClick={() => setPreview(true)}
                    onKeyPress={(e) =>
                      e.code == 'Space' || e.code == 'Enter' ? setPreview(true) : null
                    }
                    role="button"
                    tabIndex={0}>
                    Take Quiz <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-0" style={{ padding: '0' }}>
              {img && (
                <div className={styles.embedContainer}>
                  <LazyImage
                    className={styles.coverImg}
                    activeClassName={styles.active}
                    src={img}
                  />
                </div>
              )}
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div
            className="row no-gutters align-items-stretch justify-content-center"
            style={{ borderRadius: '32px', overflow: 'hidden', background: `#edf0ff` }}>
            <div className="col-lg-6 col-xl-5 order-lg-1">
              <div className={styles.formContainer}>
                <p
                  className="heading-xs-upper color-purple-500"
                  style={{ margin: '0', fontWeight: '700', fontSize: '14px' }}>
                  Get started
                </p>
                <p
                  className="heading-lg color-primary-950"
                  style={{ margin: `0`, marginTop: '1rem', fontWeight: '700' }}>
                  Make your own quiz with AI
                </p>
                <p className="heading-sm color-primary-950" style={{ margin: `1rem 0 2rem 0` }}>
                  Enter your website URL to create a quiz for your brand in one minute.
                </p>
                <form method="get" action="https://ai.tryinteract.com/create">
                  <div className={styles.inputContainer}>
                    <input
                      type="text"
                      placeholder="https://www.mysite.com"
                      name="website_url"></input>
                    <Button theme="primary" type="submit" size="large" style={{ gap: '8px' }}>
                      <FontAwesomeIcon icon={faSparkles} /> Generate quiz
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-7 order-lg-0 text-center">
              <GatsbyImage image={quizImage} alt="Interact template screenshots" />
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-12">
              <p className="heading-sm color-black" style={{ margin: `0`, fontWeight: '500' }}>
                Similar <span style={{ textTransform: `lowercase` }}>{name}</span> quizzes
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: `1rem` }}>
            {data.relatedTemplates.edges.map(({ node }) => {
              const { title, slug, img, n_questions } = node.data;
              return (
                <div className="col-lg-4 col-md-6" style={{ marginBottom: `1.5rem` }} key={slug}>
                  <Link to={`/quiz/${slug}/`}>
                    <div className={styles.card}>
                      <LazyImage className={styles.img} activeClassName={styles.active} src={img} />
                      <div className={styles.content}>
                        <p className="text-body color-black">{title}</p>
                        <p>{n_questions} questions</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <Button
                theme="outline"
                type="internal"
                href={`/quizzes/${slug}/`}
                style={{ marginTop: `2rem` }}>
                See more quizzes
              </Button>
            </div>
          </div>
        </Section>

        <Section theme="grey" hideContainer="true">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-9">
                <h3 className="heading-xl color-black text-center">Quiz Transcript</h3>
              </div>
            </div>
          </div>
          {rawData ? <PreviewCards rawData={rawData} page={true} /> : null}
        </Section>

        <Callout />

        {preview ? (
          <Modal
            className={styles.previewModal}
            isOpen={true}
            onClose={() => setPreview(false)}
            overlayColor={design?.bgColor || '#fffff'}
            closeBtnColor={design?.fontColor || '#000000'}>
            <div className={styles.content}>
              <InteractEmbed id={id} template={true} />
            </div>
            <div className={styles.useTemplateContainer}>
              <div className={styles.useTemplate}>
                <p className="text-body">
                  Make your own quiz <strong>with AI</strong>
                </p>
                <Button theme="primary" type="external" href="https://ai.tryinteract.com/create">
                  Make a quiz - free
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
      </main>
      <Footer />
    </>
  );
};

export default QuizTemplatePageTemplate;

export const pageQuery = graphql`
  query ($slug: String!, $category: String!) {
    quizTemplate: airtable(table: { eq: "Templates" }, data: { slug: { eq: $slug } }) {
      data {
        id
        title
        slug
        img
        content
        category_record {
          data {
            slug
            name
            active
          }
        }
      }
    }
    relatedTemplates: allAirtable(
      filter: {
        table: { eq: "Templates" }
        data: { active: { eq: true }, featured_category: { eq: $category }, slug: { ne: $slug } }
      }
      sort: { fields: data___created_at, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          data {
            id
            title
            slug
            img
            n_questions
          }
        }
      }
    }
    quizImage: file(relativePath: { eq: "landing-quiz.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: { cropFocus: CENTER })
      }
    }
  }
`;
